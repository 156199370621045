$ ->
  $currentImage = null
  originalTitle = $("nav#top h1").html()

  onLoaded = ->
    $("#show-image").find(".center-loading").hide()

  onLoading = ->
    $("#show-image").find(".center-loading").show()

  showImage = ($imageWrapper)->
    # get selected image (element and href)
    $img = $imageWrapper.find("img")
    $currentImage = $img
    href = if $imageWrapper.data "img" then $imageWrapper.data "img" else $img.attr("src")
    imageId = $img.closest("a").data("id")
    name = $img.closest("a").data("name")

    onLoading()
    
    # render image
    $("#image").attr("src", href).data("id", imageId)

    # hide other stuff
    $(".ui-draggable").hide()
    $(".action").hide()
    $(".games").hide()

    # show image itself and icon to close preview
    $("#close-overlay").show()
    $("#show-image").show()

    # set download button
    $download = $("#download")
    if $download.length > 0
      newUrl = $download.attr("href").replace(/image=\d+/, "image=" + imageId)
      $download.attr("href", newUrl)
    if name
      $("nav#top h1").html(name)

    $("#gallery-menu h2").hide()

    # set next/prev buttons if applicable
    $parent = $img.parent().parent().parent()
    $prev = $parent.prev()
    $next = $parent.next()
    if $next.length
      $("#gallery_next").show()
    else
      $("#gallery_next").hide()

    if $prev.length
      $("#gallery_prev").show()
    else
      $("#gallery_prev").hide()

  $(".preview").click (e)->
    e.preventDefault()
    showImage $(e.currentTarget)

  $("#gallery_next").click (e)->
    e.preventDefault()
    $wrapperNext = $currentImage.parent().parent().parent().next().find(">a")
    showImage $wrapperNext

  $("#gallery_prev").click (e)->
    e.preventDefault()
    $wrapperPrev = $currentImage.parent().parent().parent().prev().find(">a")
    showImage $wrapperPrev

  $("#close-overlay").click (e)->
    e.preventDefault()
    $(".ui-draggable").show()
    $("#prev-img").hide()
    $(".action").show()
    $("#show-image").hide()
    $(".games").show()
    $("#close-overlay").hide()
    $("nav#top h1").html(originalTitle)
    $("#gallery-menu h2").show()

  $("#print").click (e)->
    e.preventDefault()
    popup = window.open($("#image").attr("src"))
    popup.focus()
    popup.print()
    setTimeout ->
        popup.close()
    ,100

  $("#show-image").css
    "height" : $(window).height() - (parseInt($(window).height())*0.2)

  $("#image").load ->
    onLoaded()
